<template>
    <div>
        <el-breadcrumb separator=">">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>
            <a href="#">基础信息管理</a>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
            <a href="#">收费项目管理</a>
        </el-breadcrumb-item>
        </el-breadcrumb>
        <el-divider></el-divider>
        <el-row class="button-group">
        <el-button
            type="primary"
            size="small"
            plain
            icon="el-icon-circle-plus"
            @click="handleAdd(null)"
        >新增</el-button>
        </el-row>
        <el-table
            ref="formTable"
            :data="tableData"
            v-loading="loading"
            element-loading-text="拼命加载中"
            stripe
        >
            <el-table-column type="index" label="序号" width="60">
                <template slot-scope='scope'>
                    {{(pageIndex - 1)*pageSize + scope.$index + 1}}
                </template>
            </el-table-column>
            <el-table-column prop="typeN" label="收费项目类型" ></el-table-column>
            <el-table-column prop="pic" label="图标" width="100">
                <template slot-scope="{row}">
                    <el-image v-if="row.pic"
                        style="width: 40px; height: 40px"
                        :src="row.pic"></el-image>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="收费项目" ></el-table-column>
            <el-table-column prop="desc" label="收费项目说明" ></el-table-column>
            <el-table-column prop="unitPrice" label="单价" ></el-table-column>
            <el-table-column prop="unit" label="单位" ></el-table-column>
            <el-table-column prop="isActivation" label="激活开关" >
                <template slot-scope="{row}">
                    <el-switch
                        v-model="row.isActivation"
                        active-color="#13ce66"
                        inactive-color="#ff4949">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column label="操作" fixed="right" width="200">
                <template slot-scope="{row}">
                <el-button size="mini" type="warning" @click="handleEdit(row)">编辑</el-button>
                <el-button size="mini" type="danger" @click="handleDelete(row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:center;">
            <el-pagination
                :current-page.sync="pageIndex"
                :total="totalElements"
                :page-sizes="pageSizeList"
                @current-change="changePage"
                @size-change="pageSizeChange"
                layout="total, sizes, prev, pager, next,  jumper"
            ></el-pagination>
        </div>
        <payService-detail
            v-if="showModal"
            :businessKey="businessKey"
            :parentId="selectedRecord.id"
            :title="modalTitle"
            @close="onDetailModalClose"
        ></payService-detail>
    </div>
</template>
<script>
import Constant from "@/constant";
import payServiceApi from "@/api/base/payService";
import payServiceDetail from "./payService-detail";
export default {
    name: 'basePayServiceList',
    data(){
        return{
            loading: false,
            showModal: false,
            tableData: [],
            multipleSelection: [],
            pageIndex: 1,
            pageSize: 10,
            totalElements: 0,
            tableHeight: 400,
            pageSizeList: [10,30,50,100],
            companyId:"",
        }
    },
    methods:{
        changePage(pageIndex,companyId) {
            this.loading = true;
            var formData = new FormData();

            formData.append("pageIndex", this.pageIndex);
            formData.append("pageSize", this.pageSize);

            if(companyId!=null){
                formData.append("companyId", companyId);
            }

            payServiceApi.payServicePageList(formData).then(response => {
                this.loading = false;
                var jsonData = response.data;

                console.log(jsonData);

                this.tableData = jsonData.data.data;
                this.totalElements = jsonData.data.recordsTotal;

                this.pageIndex = pageIndex;
            });
        },
        pageSizeChange(pageSize) {
            this.pageSize = pageSize;
            
            this.$nextTick(()=>{
                this.changePage(1,this.companyId);
            });
        },
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        onDetailModalClose(retObj) {
            //保存成功后回调
            this.showModal = false;

            if (retObj.result) {
                var retData = retObj.data;

                this.changePage(this.pageIndex,this.companyId);

            }
        },
        handleAdd(record) {
            this.modalTitle = "新增";
            this.operation = "add";
            this.businessKey = "";

            if(record!=null){
                this.selectedRecord = record;
            }
            else{
                this.selectedRecord = {};
            }

            this.showModal = true;
        },
        handleEdit(record) {
            this.modalTitle = "编辑";
            this.operation = "edit";
            this.businessKey = record.id;
            this.selectedRecord = record;
            this.showModal = true;
        },
        handleDelete(record) {
            var self = this;

            self.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
                })
                .then(() => {
                payServiceApi.remove(record.id).then(function(response) {
                    var jsonData = response.data;

                    if (jsonData.result) {
                    var rs = self.loopDelete(self.tableData,record.id);

                    self.$message({
                        type: "success",
                        message: "删除成功!"
                    });
                    }
                });
            });
        },
        loopDelete(list,id){
            var rs = false;

            for(var i=0;i<list.length;i++){
                if(list[i].id == id){
                list.splice(i,1);
                rs = true;
                break;
                }

                if(list[i].children!=null){
                rs = this.loopDelete(list[i].children,id);

                if(rs){
                    break;
                }
                }
            }

            return rs;
        },   
        
    },
    mounted() {
        var companyId = this.$route.query.companyId;
        this.companyId = companyId;
        this.changePage(1,companyId);
    },
    components: {
        "payService-detail": payServiceDetail
    }
    
}
</script>
<style scoped>
.el-divider {
  margin: 5px 0;
}

.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}
.order-list{
    text-align: left;
}
.button-group {
  padding: 10px;
  text-align: left;
}
</style>