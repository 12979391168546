<template>
        <el-dialog
            width="50%"
            :visible.sync="showDialog"
            :title="title"
            :modal-append-to-body="true"
            :append-to-body="true"
            style="text-align:left;"
            @close="closeDialog"
            :close-on-click-modal="false"
            >
            <div class="user-panel" v-loading="loading">
            <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'80px'">
                <el-form-item label="项目类型" prop="type" >
                    <el-select v-model="formModel.type" style="width:300px">
                        <el-option
                        v-for="useType in typeList"
                        :key="useType.id"
                        :label="useType.goodsName"
                        :value="useType.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="收费项目" prop="name">
                    <el-input v-model="formModel.name" placeholder="请输入名称" style="width:300px"></el-input>
                </el-form-item>
                <el-form-item label="收费说明" prop="desc">
                    <el-input v-model="formModel.desc" placeholder="请输入名称" style="width:300px"></el-input>
                </el-form-item>
                <el-form-item label="单价" prop="unitPrice">
                    <el-input v-model="formModel.unitPrice" placeholder="请输入单价" style="width:300px"></el-input>
                </el-form-item>
                <el-form-item label="单位" prop="unit">
                    <el-input v-model="formModel.unit" placeholder="请输入单位" style="width:300px"></el-input>
                </el-form-item>
                <el-form-item label="是否激活" prop="isActivation">
                    <el-switch
                        v-model="formModel.isActivation"
                        active-color="#13ce66"
                        inactive-color="#ff4949">
                    </el-switch>
                </el-form-item>

            </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取 消</el-button>
            <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
            </span>
        </el-dialog>
</template>
<script>
import Constant from "@/constant";
import payServiceApi from "@/api/base/payService";
export default {
    props: ["businessKey", "title"],
    data(){
        return{
            ruleValidate: {
                type: [{ required: true, message: "类型不能为空", trigger: "blur" }],
                name: [{ required: true, message: "收费项目不能为空", trigger: "blur" }],
                desc: [{ required: true, message: "收费说明不能为空", trigger: "blur" }],
                unitPrice: [{ required: true, message: "单价不能为空", trigger: "blur" }],
                unit: [{ required: true, message: "单位不能为空", trigger: "blur" }],
            },
            formModel:{
                type:"",
                name:"",
                isActivation:true,
            },
            typeList:[],
            showDialog: true,
            loading: false,
            submitting: false,
        }
    },
    created() {
        var self = this;

        payServiceApi.goodsTypeList().then(response => {
            var jsonData = response.data;
            this.typeList = jsonData.data;
        });
    },
    methods: {
        closeDialog() {
            this.$emit("close", false);
        },
        handleSubmit() {
            var self = this;
            this.$refs["form"].validate(valid => {
                if (valid) {
                (function() {
                    var id = self.formModel.id;

                    if (id == null || id.length == 0) {
                    return payServiceApi.addPayService(self.formModel);
                    } else {
                    return payServiceApi.update(self.formModel);
                    }
                })().then(function(response) {
                    var jsonData = response.data;

                    if (jsonData.result) {
                    self.$message({
                        message: "保存成功!",
                        type: "success"
                    });

                    self.$emit("close", {
                        result : true,
                        data : jsonData.data
                    });
                    } else {
                    self.$message({
                        message: jsonData.message + "",
                        type: "warning"
                    });

                    self.$emit("close", {
                        result : false
                    });
                    }
                });
                }
            });
        },
    },
    async mounted() {
        var self = this;
        self.loading = true;

        (function() {
        if (self.businessKey.length == 0) {
            return payServiceApi.create(self.parentId);
        } else {
            return payServiceApi.editPayService(self.businessKey);
        }
        })()
        .then(response => {
            var jsonData = response.data;
            self.loading = false;

            if (jsonData.result) {
                self.formModel = jsonData.data;
            } else {
                self.$message.error(jsonData.message + "");
            }
        })
        .catch(error => {
            self.$message.error(error + "");
        });
    }
}
</script>
<style scoped>
.user-panel {
  margin: 10px auto;
  margin-left: 20px;
}
</style>